import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

const HeaderBar = () => {
  const { pathname } = useLocation();

  return (
    <Wrap className="header-bar flex items-center fixed top-0 w-full md:mt-6 mb-4 ml-0 z-20 justify-between">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center">
        <div className="flex space-x-8 order-2 md:order-1">
          <Link className={`text-lg cursor-pointer hover:opacity-100 ${pathname === '/' ? 'opacity-100' : 'opacity-60'}`} to="/">
            Home
          </Link>
          <Link className={`text-lg cursor-pointer hover:opacity-100 ${pathname?.includes('/works') ? 'opacity-100' : 'opacity-60'}`} to="/works">
            Paintings
          </Link>
          <a href="mailto:kristendjin@gmail.com" className="text-lg cursor-pointer opacity-60 hover:opacity-100">
            Contact
          </a>
          <a target="_blank" href="https://instagram.com/krybbykris" className="text-lg cursor-pointer opacity-60 hover:opacity-100">
            Instagram
          </a>
        </div>
        <div className="md:mr-10 order-1 md:order-2 mb-2 md:mb-0">
          <span className="text-2xl">Kristen Djin</span>
        </div>
      </div>
    </Wrap>
  );
};

const Wrap = styled.div`
  span {
    font-family: omnes-pro, sans-serif;
    font-weight: 700;
    font-style: normal;
  }
`;

export default HeaderBar;
